try {
    if (window.location.search.includes('isMobile=true')) {
        window['gfbm'] = true;
        localStorage.setItem('gfbm', JSON.stringify({ 'mobile': 'true' }));
    }
}
catch { }

function myFunction() {
    console.log("Hello from JavaScript!");
  }